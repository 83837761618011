<template>
  <div class="page">
    <div class="wrapper illustration illustration_brackets">
      <Backlink title="questionnaire" service="credit" backlink="/credit/problems" />
      <div class="content">
        <AboutProblems service="credit" nextlink="/credit/delay" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutProblems from '@/components/service/AboutProblems.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Credit_about_problems',
  components: { Backlink, AboutProblems }
}
</script>
